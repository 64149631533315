/*
 * @Author: wangqs 
 * @description 测试模块
 * @Date: 2021-07-11 16:24:29 
 * @Last Modified by: wangqs
 * @Last Modified time: 2021-11-19 10:56:47
 */
<template>
  <div class="page-box box">
    <!-- 基本信息 -->
    <div class="info-box box">
      <div class="title">
        基本信息
      </div>
      <div
        class="box content-box"
      >
        <van-field
          v-model="orderInfo.userName"
          :class="{'red-text': editKey.includes('userName')}"
          :disabled="!editKey.includes('userName')"
          label="姓名"
          input-align="right"
          placeholder="--"
        />
        <van-field
          v-model="orderInfo.idCardNo"
          :class="{'red-text': editKey.includes('idCardNo')}"
          :disabled="!editKey.includes('idCardNo')"
          label="身份证号码"
          input-align="right"
          placeholder="请输入本人身份证号码"
        />
        <!-- 允许输入正整数，调起纯数字键盘 -->
        <van-field
          v-model="orderInfo.bankCardNo"
          :class="{'red-text': editKey.includes('bankCardNo')}"
          :disabled="!editKey.includes('bankCardNo')"
          type="digit"
          input-align="right"
          placeholder="--"
          label="储蓄卡号码"
        />
        <!-- 输入手机号，调起手机号键盘 -->
        <van-field
          v-model="orderInfo.mobile"
          :class="{'red-text': editKey.includes('mobile')}"
          :disabled="!editKey.includes('mobile')"
          type="tel"
          input-align="right"
          placeholder="--"
          label="预留手机号"
        />
      </div>
    </div>

    <!-- 学员信息 -->
    <div class="info-box box">
      <div class="title">
        学员信息
      </div>
      <div
        class="box content-box"
      >
        <van-field
          v-model="orderInfo.childName"
          :class="{'red-text': editKey.includes('childName')}"
          :disabled="!editKey.includes('childName')"
          input-align="right"
          label="学员姓名"
          placeholder="--"
        />
        <van-field
          input-align="right"
          readonly
          :class="{'red-text': editKey.includes('birthDay')}"
          :disabled="!editKey.includes('birthDay')"
          clickable
          required
          name="datetimePicker"
          :value="getTime(orderInfo.birthDay)"
          label="学员出生日期"
          placeholder="点击选择时间"
          @click="editKey.includes('birthDay') ? showPickerTime = true : ''"
        />
        <van-popup
          v-model="showPickerTime"
          position="bottom"
        >
          <van-datetime-picker
            v-model="orderInfo.birthDay"
            type="date"
            title="选择出生日期"
            :columns-order="['year', 'month', 'day']"
            :min-date="minDate"
            :max-date="maxDate"
            :formatter="formatter"
            @confirm="onConfirmTime"
            @cancel="showPickerTime = false"
          />
        </van-popup>
        <van-field
          readonly
          input-align="right"
          :class="{'red-text': editKey.includes('relation')}"
          :disabled="!editKey.includes('relation')"
          name="picker"
          :value="relationObj[orderInfo.relation]"
          label="关系"
          placeholder="选择与学员关系"
          @click="showPickerC = true"
        />
        <van-popup
          v-model="showPickerC"
          position="bottom"
        >
          <van-picker
            show-toolbar
            :columns="relationArr"
            @confirm="onConfirmC"
            @cancel="showPickerC = false"
          />
        </van-popup>
        <van-field
          v-model="orderInfo.childIdCardNo"
          :class="{'red-text': editKey.includes('childIdCardNo')}"
          :disabled="!editKey.includes('childIdCardNo')"
          input-align="right"
          placeholder="--"
          label="身份证号"
        />
      </div>
    </div>

    <!-- 授权信息 -->
    <div class="info-box box">
      <div class="title">
        授信信息
      </div>
      <div
        class="box content-box"
      >
        <van-field
          v-model="orderInfo.companyName"
          :class="{'red-text': editKey.includes('companyName')}"
          :disabled="!editKey.includes('companyName')"
          input-align="right"
          label="单位名称"
          placeholder="--"
        />
        <van-field
          v-model="orderInfo.workAddr"
          :class="{'red-text': editKey.includes('workAddr')}"
          :disabled="!editKey.includes('workAddr')"
          input-align="right"
          label="单位地址"
          placeholder="--"
        />
        <van-field
          v-model="orderInfo.email"
          :class="{'red-text': editKey.includes('email')}"
          :disabled="!editKey.includes('email')"
          input-align="right"
          placeholder="--"
          label="邮箱"
        />
        <van-field
          readonly
          input-align="right"
          :class="{'red-text': editKey.includes('addr')}"
          :disabled="!editKey.includes('addr')"
          name="area"
          :value="addrStr"
          label="家庭地址"
          placeholder="点击选择省市区"
          @click="showArea = true"
        />
        <van-popup
          v-model="showArea"
          position="bottom"
        >
          <van-area
            :value="orderInfo.areaNo || ''"
            :area-list="areaList"
            @confirm="onConfirmAddr"
            @cancel="showArea = false"
          />
        </van-popup>

        <van-field
          v-model="orderInfo.detailAddr"
          input-align="right"
          :class="{'red-text': editKey.includes('addr')}"
          :disabled="!editKey.includes('addr')"
          label="详细地址"
          placeholder="请输入家庭详细地址"
        />
        <van-field
          readonly
          :class="{'red-text': editKey.includes('income')}"
          :disabled="!editKey.includes('income')"
          input-align="right"
          clickable
          name="picker"
          :value="orderInfo.income"
          label="家庭收入"
          placeholder="点击选家庭收入"
          @click="editKey.includes('income') ? showPickerA = true : ''"
        />
        <van-popup
          v-model="showPickerA"
          position="bottom"
        >
          <van-picker
            show-toolbar
            :columns="incomeArr"
            @confirm="onConfirmA"
            @cancel="showPickerA = false"
          />
        </van-popup>
        <van-field
          readonly
          :class="{'red-text': editKey.includes('education')}"
          :disabled="!editKey.includes('education')"
          input-align="right"
          name="picker"
          :value="orderInfo.education"
          label="学历"
          placeholder="点击选学历"
          @click="showPickerB = true"
        />
        <van-popup
          v-model="showPickerB"
          position="bottom"
        >
          <van-picker
            show-toolbar
            :columns="educationArr"
            @confirm="onConfirmB"
            @cancel="showPickerB = false"
          />
        </van-popup>
        <van-field
          v-model="orderInfo.contractName"
          :class="{'red-text': editKey.includes('contractName')}"
          :disabled="!editKey.includes('contractName')"
          input-align="right"
          placeholder="--"
          label="紧急联系人"
        />
        <van-field
          v-model="orderInfo.contractMobile"
          :class="{'red-text': editKey.includes('contractMobile')}"
          :disabled="!editKey.includes('contractMobile')"
          input-align="right"
          type="digit"
          placeholder="--"
          label="紧急联系人电话"
        />
      </div>
    </div>

    <!-- 上传资料 -->
    <div class="info-box box">
      <div class="title">
        上传资料
      </div>
      <div class="content-box box">
        <template v-for="(val, key) in uploadFrom">
          <div
            :key="key"
            class="upload-box"
          >
            <div
              v-show="!editKey.includes(key)"
              class="cover-box box"
            ></div>
            <div
              class="title"
              :class="{'red-text': editKey.includes(key)}"
            >
              <span
                v-if="key === 'offlineProtocolList' || key === 'receiptList' || key === 'parentageList'"
                style="color: #D5423E;"
              >*</span>
              {{ formObj[key] || '亲子证明' }}
            </div>
            <div class="box upload-in-box">
              <div
                v-for="(item,index) in orderInfo[key] || []"
                :key="index"
                class="van-uploader__preview"
              >
                <div class="van-image van-uploader__preview-image">
                  <img
                    :src="item"
                    class="van-image__img"
                    style="object-fit: cover;"
                  >
                </div>
                <div
                  class="van-uploader__preview-delete"
                  @click="orderInfo[key].splice(index, 1)"
                >
                  <i class="van-icon van-icon-cross van-uploader__preview-delete-icon">
                  </i>
                </div>
              </div>
              <div
                class="upload-btn"
              >
                <van-uploader
                  v-model="uploadFrom[key]"
                  :after-read="(query)=>{afterRead(query, key)}"
                  :max-size="20 * 1024 * 1024"
                  @oversize="onOversize"
                  @delete="(e)=>{deleteItem(e, key)}"
                >
                </van-uploader>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="info-box box">
      <div class="title">
        课程信息
      </div>
      <div class="content-box box">
        <fq-pay
          v-if="loadStatus"
          ref="fqPay"
          :edit-key="editKey"
          :edit-order="orderInfo"
          @updateOrder="updateOrder"
        ></fq-pay>
      </div>
    </div>
  </div>
</template>

<script>
import FqPay from './components/fqPay.vue'
import { areaList } from '@vant/area-data';
export default {
  name: 'EdiInfo',
  components: {
    FqPay
  },
  data () {
    return {
      areaList,
      showPickerTime: false,
      minDate: new Date(1990, 0, 1),
      maxDate: new Date(),
      showArea: false,
      showPickerA: false,
      showPickerB: false,
      showPickerC: false,
      addrStr: '',
      relationObj: {
        1: '父母',
        2: '亲属',
        3: '其他'
      },
      relationArr: [
        {text: '父母', key: '1'},
        {text: '亲属', key: '2'},
        {text: '其他', key: '3'}
      ],
      incomeArr: [
        '5万以下',
        '5-30万',
        '30万以上'
      ],
      educationArr: ['小学', '初中', '高中', '大专', '本科', '硕士', '博士及以上'],
      orderInfo: {},
      editKey: [],
      formObj: {
        offlineProtocolList: '线下原始协议',
        propertyCertificateList: '财产证明',
        receiptList: '收据',
        parentageList: '亲子关系证明'
      },
      uploadFrom: {
        offlineProtocolList: [], // 线下原始协议
        propertyCertificateList: [], // 财产证明
        receiptList: [], // 收据
        parentageList: [] // 亲子证明
      },
      copyUpload: {},
      loadStatus: false
    }
  },
  computed: {
  },
  async created () {
    await this.getOrderInfo()
    this.loadStatus = true
  },
  methods: {
    async getOrderInfo () {
      let res = await this.$http.orderApi.orderDetail({id: this.$route.query.id || ''})
      console.log(res)
      if (res.code === 200) {
        this.orderInfo = res.msg || {}
        if (this.orderInfo) {
          let arr = []
          if (this.orderInfo.editKey === '') {
            for (let key in this.orderInfo || {}) {
              arr.push(key)
            }
          } else {
            if ((this.orderInfo.editKey.indexOf(',') > -1)) {
              arr = this.orderInfo.editKey.split(',')
            } else {
              arr = [this.orderInfo.editKey]
            }
          }
          this.editKey = arr
          // 省市县处理
          if (this.orderInfo.provinceNo) {
            this.addrStr = `${this.orderInfo.provinceName}/${this.orderInfo.cityName}/${this.orderInfo.areaName}` 
          }
          // 生日
          this.orderInfo.birthDay = new Date(this.orderInfo.birthDay)
        }
        window.localStorage.setItem('orderInfo', JSON.stringify(this.orderInfo))
      }
    },
    onConfirmAddr (values) {
      console.log(values, 'sss')
      this.addrStr = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join('/');
      this.orderInfo.provinceNo = values[0].code
      this.orderInfo.cityNo = values[1].code
      this.orderInfo.areaNo = values[2].code
      this.orderInfo.provinceName = values[0].name
      this.orderInfo.cityName = values[1].name
      this.orderInfo.areaName = values[2].name
      this.showArea = false;
    },
    onOversize (file) {
      console.log(file);
      this.$toast('文件大小不能超过 2M');
    },
    deleteItem (e, key) {
      console.log(e, key)
    },
    afterRead (file, key) {
      console.log(file)
      let vm = this;
      var fd = new FormData()
      // fd.append('Content-Type', 'multipart/form-data')
      fd.append('multipartFile', file.file)
      fd.append('dir', 'org')
      vm.$toast.loading({
        message: '加载中',
        forbidClick: true,
        duration: 0
      });
      var xhr = new XMLHttpRequest()
      xhr.open('POST', '/gateway/img/upload', true)
      xhr.onload = function () {
        if (Math.floor(xhr.status / 100) === 2) {
          var res = JSON.parse(xhr.response)
          if (res.code === 200) {
            console.log(res, '上传成功')
            file.url = res.msg.url || ''
            vm.orderInfo[key].push(res.msg.url || '')
            vm.$refs.fqPay.setOrderInfo(key, vm.orderInfo[key])
          } else {
            vm.$toast('上传失败，请检查后重新上传')
          }
        } else {
          // clearInterval(vm.timerClock)
          vm.$toast('上传失败')
        }
        vm.$toast.clear()
      }
      xhr.onerror = function () {
        vm.$toast.error('文件 ' + params.file.name + ' 上传失败')
      }
      xhr.setRequestHeader('token', JSON.parse(window.localStorage.getItem('userData') || '{}').token)
      xhr.send(fd)
    },
    // 收入
    onConfirmA(value) {
      this.orderInfo.income = value;
      this.$refs.fqPay.setOrderInfo('income', value)
      this.showPickerA = false;
    },
    // 学历
    onConfirmB(value) {
      this.orderInfo.education = value;
      this.$refs.fqPay.setOrderInfo('education', value)
      this.showPickerB = false;
    },
    // 关系
    onConfirmC (e) {
      this.orderInfo.relation = e.key;
      this.showPickerC = false;
    },
    onConfirmTime (time) {
      this.orderInfo.birthDay = time;
      this.showPickerTime = false;
    },
    changeInput (key) {
      this.$refs.fqPay.setOrderInfo('key', orderInfo[key])
    },
    updateOrder () {
      console.log(this.orderInfo, 'order')
      if (this.orderInfo.birthDay.getTime) {
        this.orderInfo.birthDay = this.getTimeStr(this.orderInfo.birthDay.getTime(), 'day') 
      } else {
        this.orderInfo.birthDay = this.getTimeStr(new Date(this.orderInfo.birthDay).getTime(), 'day') 
      }
      window.localStorage.setItem('orderInfo', JSON.stringify(this.orderInfo))
    },
    getTime () {
      if (this.orderInfo.birthDay) {
        let time = ''
        if (this.orderInfo.birthDay.getTime) {
          time = this.getTimeStr(this.orderInfo.birthDay.getTime(), 'day') 
        } else {
          time = this.getTimeStr(new Date(this.orderInfo.birthDay).getTime(), 'day') 
        }
        return time
      }
      return ''
    },
    formatter(type, val) {
      if (type === 'year') {
        return val + '年';
      }
      if (type === 'month') {
        return val + '月';
      }
      if (type === 'day') {
        return val + '日';
      }
      return val;
    },
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  background: #f5f5f5;
  .info-box {
    .title {
      padding: 0 15px; 
      line-height: 34px;
      height: 34px;
      font-size: 15px;
      font-weight: bold;
    }
    .content-box {
      background: #fff;
      /deep/ .top-box {
        margin-top: 0;
      }
    }
  }
  .upload-in-box {
    padding: 0 15px;
    .van-uploader__preview, .upload-btn {
      float: left;
      position: relative;
    }
    .upload-btn {
      /deep/ .van-uploader__preview {
        display: none;
      }
    }
  }
  .upload-box {
    position: relative;
    .cover-box {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
      position: absolute;
      left: 0;
      top: 0;
      z-index: 9;
    }
  }
}
</style>
