<template>
  <div
    class="page-box box"
    :class="{'fixed-page': editOrder}"
  >
    <!-- 信息 -->
    <div class="top-box box">
      <div class="img-box">
        <!-- <img
          src="@/assets/img/course-pay.png"
          alt=""
        > -->
        <van-image :src="courseDetail.imgUrls && courseDetail.imgUrls[0]"></van-image>
      </div>
      <div class="text-a ellipsis2">
        {{ courseDetail.courseName || '--' }}
      </div>

      <div class="addr-box ellipsis">
        <van-icon
          class="location-icon"
          name="location"
          style="color:#51B464"
        />
        {{ courseDetail.schoolName || '--' }}
      </div>
    </div>

    <!-- 课程金额 -->
    <van-field
      v-model="orderInfo.totalAmount"
      type="number"
      label="课程金额"
      placeholder="输入课程总金额"
      input-align="right"
      :class="{'red-text': editKey.includes('totalAmount')}"
      :disabled="!editKey.includes('totalAmount')"
    />

    <!-- 付款方式 -->
    <div class="pay-type box">
      <div class="select-pay-box box">
        <div
          class="text-a"
          :class="{'red-text': editKey.includes('percent')}"
        >
          首付比例（元）
        </div>
        <div class="text-b">
          {{ ((orderInfo.totalAmount || 0) * (radio/100)).toFixed(2) }}
        </div>
        <div class="radio-box box">
          <van-radio-group
            v-if="radio"
            v-model="radio"
            :disabled="!editKey.includes('percent')"
          >
            <van-radio
              v-for="item in orderInfo.percentList || []"
              :key="item"
              :name="item"
            >
              {{ item }}%
            </van-radio>
          </van-radio-group>
        </div>
      </div>

      <div class="box plan-box">
        <div
          class="b-text-a"
          :class="{'red-text': editKey.includes('period')}"
        >
          <span style="color: #C20E0E">*</span>
          分期方案
        </div>
        <div class="b-text-b">
          {{ ((orderInfo.totalAmount || 0) * ((100 - radio) / 100)).toFixed(2) }}
        </div>
        <div class="b-text-c">
          代付金额(元)
        </div>

        <div class="box">
          <div
            v-for="(item, index) in orderInfo.periodList || []"
            :key="index"
            class="radio-b-box box"
          >
            <div class="left-text">
              <!-- 分{{ item.period }}期*每期{{ ((periodNum / item.period) + (periodNum * (item.rate / 100) / 12)).toFixed(2) }}元（含手续费{{ (periodNum * (item.rate / 100) / 12).toFixed(2) }}元） -->
              分{{ (item.period || 12) }}期*每期{{ ((periodNum / (item.period || 12)) + (periodNum * (item.rate / 100) / (item.period || 12))).toFixed(2) }}元（含手续费{{ (periodNum * (item.rate / 100) / (item.period || 12)).toFixed(2) }}元）
            </div>
            <van-radio-group
              v-model="radioB"
              :disabled="!editKey.includes('period')"
            >
              <van-radio :name="index"></van-radio>
            </van-radio-group>
          </div>
        </div>
      </div>
    </div>

    <!-- 支付 -->
    <van-button
      class="submit-btn"
      color="#51B464"
      round
      type="info"
      @click="saveBtn"
    >
      提交
    </van-button>
  </div>
</template>

<script>
export default {
  name: 'FqPayComponent',
  components: {
  },
  props: {
    editOrder: {
      default () {
        return null
      },
      type: Object
    },
    editKey: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      mobile: '',
      radio: '',
      radioB: 0,
      orderInfo: {
        totalAmount: 0
      },
      courseDetail: {},
      uploadFrom: {
        offlineProtocolList: [], // 线下原始协议
        propertyCertificateList: [], // 财产证明
        receiptList: [], // 收据
        parentageList: [] // 亲子证明
      }
    }
  },
  computed: {
    periodNum () {
      return ((this.orderInfo.totalAmount || 0) * ((100 - this.radio) / 100)).toFixed(2)
    }
  },
  async created () {
    let orderInfo = JSON.parse(window.localStorage.getItem('orderInfo') || '{}')
    this.radio = orderInfo.percent
    this.orderInfo = orderInfo
    let res = await this.$http.homeApi.courseDetail({id: orderInfo.courseNo})
    if (res.code === 200) {
      this.courseDetail = res.msg || {}
    }
    for (let j in this.uploadFrom) {
      if (orderInfo.j && orderInfo.j.indexOf('[') > -1) {
        orderInfo.j = JSON.parse(orderInfo.j)
      }
    }
    for (let i = 0; i < orderInfo.periodList.length; i++) {
      if (orderInfo.periodList[i].period == orderInfo.period) {
        this.radioB = i
      }
    }
    console.log(this.radioB, this.radio)
    this.$set(this.orderInfo, 'totalAmount', orderInfo.totalAmount || orderInfo.amount || 0)
    this.radio = (orderInfo.percent && orderInfo.percent.toString()) || orderInfo.percentList[0] || ''
  },
  methods: {
    setOrderInfo (key, flag) {
      console.log(key, flag, '进入')
      // this.$set(this.orderInfo, key, flag)
      this.orderInfo[key] = flag
    },
    filterParams () {
      if (!this.orderInfo.workAddr) {
        this.$toast('请填写工作单位')
      } else if (!this.orderInfo.email) {
        this.$toast('请填写邮箱')
      } else if (!this.orderInfo.email) {
        this.$toast('请填写公司名称')
      } else if (!(this.orderInfo.companyName)) {
        this.$toast('请选择家庭地址省市县')
      } else if (!(this.orderInfo.detailAddr)) {
        this.$toast('请填写家庭详细地址')
      } else if (!this.orderInfo.income) {
        this.$toast('请填写收入')
      } else if (!this.orderInfo.education) {
        this.$toast('请填写学历')
      } else if (!this.orderInfo.contractName) {
        this.$toast('请填写紧急联系人姓名')
      } else if (!this.orderInfo.contractMobile) {
        this.$toast('请填写紧急联系人号码')
      } else {
        return true
      }
    },
    // 编辑的时候及时获取页面信息
    getEditOrderInfo () {
      this.$emit('updateOrder')
      let orderInfo = JSON.parse(window.localStorage.getItem('orderInfo') || '{}')
      Object.assign(this.orderInfo, orderInfo)
    },
    async saveBtn () {
      if (this.orderInfo.totalAmount <= 0) {
        this.$toast('请输入正确课程金额')
      }
      if (this.filterParams()) {
        let url = 'orderEdit'
        this.getEditOrderInfo()
        let periodObj = this.orderInfo.periodList[this.radioB] || {}
        let percent = this.radio
        // period 期数 首付比例 课程金额
        this.orderInfo.period = periodObj.period
        this.orderInfo.percent = percent
        // this.orderInfo.receiptList = this.orderInfo.receipt || []
        // this.orderInfo.offlineProtocolList = this.orderInfo.offlineProtocol || []
        console.log(this.orderInfo, 'info')
        let res = await this.$http.orderApi[url](this.orderInfo)
        console.log(res, 'resorder')
        if (res.code === 200) {
          this.$toast.success('提交成功')
          window.localStorage.removeItem('orderInfo')
          window.localStorage.removeItem('courseDetail')
          setTimeout(() => {
            this.$router.back()
          }, 1500)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  // height: 100vh;
  background: #f5f5f5;
  .top-box {
    background: #fff;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 20px 15px 20px 174px;
    min-height: 117px;
    .img-box {
      width: 144px;
      height: 77px;
      position: absolute;
      left: 15px;
      top: 20px;
      .van-image, img {
        width: 100%;
        height: 100%;
      }
    }
    .text-a {
      line-height: 24px;
      font-size: 14px;
    }
    .price-box {
      margin-top: 16px;
      color: #D5423E;
      font-size: 12px;
    }
  }
  .mobile-box {
    background: #fff;
    margin-top: 10px;
  }

  .pay-type {
    background: #fff;
    margin-top: 10px;
    .select-pay-box {
      padding: 15px;
      text-align: center;
      padding-bottom: 20px;
      .text-a {
        padding-top: 20px;
        font-size: 14px;
      }
      .text-b {
        width: 205px;
        color: #51B464;
        font-size: 30px;
        font-weight: bold;
        line-height: 40px;
        margin: 20px auto 0;
        border-bottom: 1px dashed #A3A3A3;
        margin-bottom: 20px;
      }
      .van-radio {
        margin: 0 12px;
        float: left;
      }
    }
  }
  .pay-btn-box {
    width: 100%;
    height: 55px;
    line-height: 55px;
    padding: 0 20px;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #fff;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
    .text {
      color: #D5423E;
      font-size: 22px;
      font-weight: bold;
      span {
        font-size: 12px;
        line-height: 55px;
      }
    }
    .pay-btn {
      width: 230px;
      height: 40px;
      line-height: 40px;
      background: #282734;
      color: #fff;
      font-size: 14px;
      text-align: center;
      position: absolute;
      right: 20px;
      top: 7.5px;
      border-radius: 20px;
    }
  }

  .addr-box {
    font-size: x-small;
    font-size: 10px;
    line-height: 14px;
    color: #8a8a8a;
    margin-top: 5px;
    padding-left: 16px;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    .location-icon {
      position: absolute;
      left: 0;
      top: 0;
      line-height:12px;
      font-size: 12px;
      color: #51B464;
    }
  }

  .plan-box {
    padding: 15px;
    background: #F2F3F7;
    .b-text-a {
      color: #282734;
      line-height: 18px;
    }
    .b-text-b {
      margin-top: 16px;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
    }
    .b-text-c {
      color: #646464;
      font-size: x-small;
      font-size: 11px;
      text-align: center;
    }
    .radio-b-box {
      margin-top: 10px;
      line-height: 48px;
      .left-text {
        color: #646464;
        font-size: 14px;
        float: left;
      }
      .van-radio {
        float: right;
        margin-top: 12px;
      }
    }
  }
  

  .submit-btn {
    margin: 50px auto;
    display: block;
    width: 346px;
  }
}
.fixed-page {
  padding-bottom: 80px;
  .submit-btn {
    margin: 0;
    position: fixed;
    left: 50%;
    margin-left: -173px;
    bottom: 20px;
    z-index: 100;
  }
}
</style>
